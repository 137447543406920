<script setup lang="ts">
/********************
 * PROPS & EMITS     *
 ********************/
export interface CiUserAvatarProps {
  bgClass?: string;
  border?: boolean;
  lightTheme?: boolean;
  size?: 'sm' | 'md' | 'lg' | 'xl';
  userImage?: string;
}
const props = withDefaults(defineProps<CiUserAvatarProps>(), {
  bgClass: '',
  border: false,
  lightTheme: false,
  size: 'md',
  userImage: '',
});
</script>

<template>
  <div class="relative">
    <div :class="[`user-icon user-icon--${props.size} relative`, { 'user-icon--border': props.border }]">
      <img
        v-if="props.userImage"
        :src="userImage"
        class="user-icon__image relative block rounded-full"
        alt="user image"
      />
      <img
        v-else-if="props.lightTheme"
        :class="props.bgClass"
        class="user-icon__image relative block rounded-full"
        src="~/assets/svg/user_placeholder.svg"
        alt="user image placeholder"
        width="52"
        height="52"
      />
      <img
        v-else
        :class="props.bgClass"
        class="user-icon__image relative block rounded-full"
        src="~/assets/svg/user_placeholder.grey.svg"
        alt="user image placeholder"
        width="52"
        height="52"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
$userIconSize: 2rem;
$userIconSizeL: 3.25rem;
$userIconSizeSm: 1.5rem;
$userIconSizeXL: 4.75rem;

.user-icon {
  .user-icon__image {
    width: $userIconSize;
  }

  &.user-icon--sm {
    + .user-icon-menu {
      top: $userIconSizeSm * 1.75;
    }

    .user-icon__image {
      width: $userIconSizeSm;
    }
  }

  &.user-icon--md {
    .user-icon__image {
      width: $userIconSize;
    }
  }

  &.user-icon--lg {
    .user-icon__image {
      width: $userIconSizeL;
    }
  }

  &.user-icon--xl {
    .user-icon__image {
      width: $userIconSizeXL;
    }
  }

  &.user-icon--border {
    img {
      border: 5px solid $white;
    }
  }
}
</style>
